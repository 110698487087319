<template>
  <div class="col-12 c">
    <div class="col-12 text-center">
        <div class="alert alert-info g">
            تظهر هنا الخطط الاسبوعية المضافة بواسطة المعلمين.
        </div>
    </div>
    <div class="col-12 text-center" v-if="!arr.arr.length">
        <div class="alert alert-danger g">
            لم يتم اضافة اي خطة اسبوعية بواسطة المعلمين
        </div>
    </div>
    <div v-for="plan in arr.arr" :key="plan._id" class="card card-body">
        <p>
            <i class="fa fa-user"></i> {{ plan.teacher }} - <i class="fa fa-users"></i> {{ plan.classname }} &nbsp;
            <button class="btn btn-primary btn-sm" v-b-modal.send @click="current=plan">
                <i class="fa fa-paper-plane"></i> إرسال لأولياء الأمور
            </button>&nbsp;
            <button class="btn btn-warning btn-sm" @click="current=plan;print()">
                <i class="fa fa-print"></i> طباعة
            </button>
        </p>
        <div class="col-12 table-responsive" :id="'t'+plan._id">
            <table class="table table-sm table-bordered table-hover bg-white" style="margin-top: 5px">
                <thead>
                    <th>
                        المادة
                    </th>
                    <th></th>
                    <th>
                        الأحد
                        <br>
                        <span>{{ plan.data[0].days['1'] }}</span>
                    </th>
                    <th>
                        الاثنين
                        <br>
                        <span>{{ plan.data[0].days['2'] }}</span>
                    </th>
                    <th>
                        الثلاثاء
                        <br>
                        <span>{{ plan.data[0].days['3'] }}</span>
                    </th>
                    <th>
                        الاربعاء
                        <br>
                        <span>{{ plan.data[0].days['4'] }}</span>
                    </th>
                    <th>
                        الخميس 
                        <br>
                        <span>{{ plan.data[0].days['5'] }}</span>
                    </th>
                </thead>
                <tbody>
                    <template v-for="(subject) in plan.data[0].subjects">
                        <tr :key="subject.name">
                            <td rowspan="3" class="text-center"><br>
                                {{ subject.name }}
                            </td>
                            <td style="text-align: center">الدرس</td>
                            <td >
                                <span>{{ subject.days[1].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].title }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].title }}</span>
                            </td>
                        </tr>
                        <tr :key="subject.name + '1'">
                            <td style="text-align: center">الاهداف</td>
                            <td >
                                <span>{{ subject.days[1].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].target }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].target }}</span>
                            </td>
                        </tr>
                        <tr :key="subject.name + '2'">
                            <td style="text-align: center">الواجبات</td>
                            <td >
                                <span>{{ subject.days[1].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[2].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[3].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[4].homework }}</span>
                            </td>
                            <td >
                                <span>{{ subject.days[5].homework }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="send" hide-footer title="ارسال" size="sm">
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="3"></textarea>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                    <i class="fa fa-whatsapp"></i>
                    واتس تلقائي
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('wa')">
                    <i class="fa fa-whatsapp"></i>
                    اداة المتصفح
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-primary btn-block"  @click="send('sms')">
                    <i class="fa fa-mobile"></i>
                    SMS
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox, BFormRadio, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BFormRadio,
        BFormGroup,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            arr: [],
            students: [],
            current: {},
            message: `خطة التعليم الاسبوعي من الرابط التالي:
{link}`
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/general/weekplan', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.arr = r.response;
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200);
        })
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            q: null,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        print(){
            var g = this;
            var divToPrint=document.getElementById("t" + g.current._id);
            var newWin= window.open("");
            newWin.document.write(`
            <html dir='rtl'>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
                    <title>التحضير الذكي - tahdir.net</title>
                    <style>
                        table, td, th {
                            border: 1px solid #86AB89 !important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table, table *{
                            white-space: normal;
                        }
                        .hidemex{
                            display:none;
                        }
                        th{
                            background: #E7FBE6 !important
                        }
                    </style>
                </head>
                <body>
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-4 text-center'>
                                <h5>
                                <br><br>
                                    المملكة العربية السعودية
                                    <br>
                                    ${g.user.name}
                                </h5>
                            </div>
                            <div class='col-4 text-center'><img style='width: 200px' src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg"></div>
                            <div class='col-4 text-center'>
                            <h5>
                                <br>
                                    خطة التعلم الأسبوعي
                                    <br>
                                    الصف: ${g.current.classname}
                                    <br>
                                    المعلم: ${g.current.teacher}
                                </h5>
                            </div>
                        </div>
                        ${divToPrint.outerHTML?.replace("table-hover", "")}
                    </div>
                </body>
            </html>
            `);
            setTimeout(() => {
                newWin.document.close()
                newWin.print();
                newWin.close();
            }, 1000);
        },
        send(method){
            var g = this;
            var messages = [{
                phones: ["123"],
                message: `({https://s.tahdir.net/weekplan/${g.user._id}/classname/${encodeURI(g.current.classname)}})`
            }];
            var students = g.students.filter(function(x){
                if(x.classname == g.current.classname){
                    return x
                }else{
                    return false
                }
            })
            $.post(api + '/user/general/short-links', {
                jwt: g.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                var link = rx.response[0].message;
                students.forEach(function(a){
                    messages.push({
                        phones: a.phones,
                        message: convertToHijri(g.message.replace("{link}", `${link}`))
                    })
                })
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(messages))
                    $("#sendmodalwatype").val("students")
                    $("#sendWAModal").click()
                }else{
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(messages),
                        method: method,
                        type: "students"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            })
        },
    }
}
</script>

<style>
table, table *{
    white-space: nowrap;
}
table textarea{
    min-width: 200px;
}
</style>